import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Mezoterapia igłowa`}
        description={`Mezoterapia igłowa to jeden z najskuteczniejszych zabiegów regeneracyjnych i odżywczych. Mezoterapia polega na iniekcji indywidualnie dobranych substancji leczniczych, odżywczych i regeneracyjnych bezpośrednio do głębokich warstw skóry.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Mezoterapia igłowa</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Mezoterapia igłowa to jeden z najskuteczniejszych zabiegów
                regeneracyjnych i odżywczych. Mezoterapia polega na iniekcji
                indywidualnie dobranych substancji leczniczych, odżywczych i
                regeneracyjnych  bezpośrednio do głębokich warstw skóry. W ten
                sposób pominięta zostaje bariera naskórkowa, która blokuje
                przenikanie większości kosmetyków.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Szczegóły zabiegu</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Zabieg mezoterapii igłowej polecany jest szczególnie dla
                pacjentów borykających się z niedoskonałościami skóry,
                którzy chcą skorzystać z pełni naturalnej i przynoszącej
                długotrwałe rezultaty terapii. Wprowadzone za pomocą wkłuć
                indywidualnie dobierane substancje aktywne pobudzą procesy
                regeneracyjne i poprawią mikrokrążenie. Uzyskany efekt będzie
                wielokrotnie lepszy niż podczas stosowania nawet najdroższych
                kremów. Dzięki temu mezoterapia igłowa zyskała szeroką rzeszę
                zadowolonych klientów, zwłaszcza, że przynosi oczekiwane
                i długotrwałe efekty w bardzo krótkim czasie. Do mezoterapii
                stosujemy gotowe preparaty, albo własną krew pacjenta ( osocze
                lub fibrynę)
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h2 className="offer-item-title">Innowacyjna metoda</h2>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                W naszej klinice stosujemy innowacyjną metodę praktycznie
                bezbolesnej mezoterapii igłowej, wykorzystującą system próżniowy
                „vacum” służy do bardzo precyzyjnego deponowania śródskórnie lub
                podskórnie określonej ilości preparatu czynnego. Dzieje się to
                dzięki użyciu podciśnienia w kartridżu, które podnosząc tkanki
                niwelują wszystkie dotychczasowe trudności metody tradycyjnej
                mezoterapii - ból, siniaki, utrata preparatu oraz czas trwania
                zabiegu. Nasze urządzenie posiada w swojej ofercie kartridże 5,
                9 oraz 16 igłowe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
